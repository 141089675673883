<template>
  <div>
	<p>1、活动名称：你的心愿我买单！</p>
	<p>2、活动时间：9月27日-10月7日</p>
	<p>3、活动规则：</p>
	<p>活动期间参与定日存钱计划，许下心愿，连续存钱打卡7天即可有机会获得心愿金和小账会员！</p>
	<p>
		<span style="color: rgb(227, 55, 55);">注：补打卡和当日多次打卡均算挑战失败</span>
	</p>
	<p>4、中奖名单公布：</p>
	<p>中奖名单会在9月30日进行公布</p>
	<p>5、奖品说明：</p>
	<p>现金奖品：公布中奖名单后，我们会联系用户，手动进行1000元现金发放。若用户在7个工作日内未回复则视为主动放弃。如有疑问，中奖用户可直接联系小账客服：jzservice001</p>
	<p>会员：公布中奖名单后，会在3个工作日内发放至用户参与的手机号码当中</p>
	<p>
		<br>
	</p>
	<p>
		<span style="color: rgb(227, 55, 55);">本活动最终解释权归小账管家所有，如有疑问可添加微信：jzservice001进行咨询。</span>
	</p>
</div>
</template>
<script>
export default {
  name: 'SaveupRule',
};
</script>
<style lang="scss" scoped>
  p {
    font-size: 26px;
    line-height: 42px;
  }
</style>
