<template>
  <div class="reward-container img-common">
    <div class="head">
      <img
        src="//download-cos.yofish.com/xz-gongjushiyebu/20220915152539245-reward-head.png" alt="">
    </div>
    <div class="reward-module"
      v-for="(item, index) in rewardModuleList"
      :key="index">
      <div class="reward-module-head">
        <img
          :src="item.headUrl" alt="" />
      </div>
      <div class="reward-module-frame img-common"
        :class="`module-frame-${index + 1}`"
        :style="{ backgroundImage: `url(${item.contentUrl})` }">
        <div class="reward-cons">
          <div class="module-row"
          v-for="(list, idx) in item.userList"
          :key="idx">
          <div class="user-msg">
            <div class="avatar">
              <img :src="list.avatorUrl" alt="">
            </div>
            <div class="phone-number">{{list.number}}</div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="hand">
      <img src="//download-cos.yofish.com/xz-gongjushiyebu/20220916101650194-hand.png" alt="">
    </div>
  </div>
</template>
<script>
import rewardModuleList from './reward.json';

export default {
  name: 'Reward',
  data() {
    return {
      rewardModuleList,
    };
  },
};
</script>
<style lang="scss" scoped>
  .reward-container {
    width: 750px;
    height: 3726px;
    overflow: hidden;
    background-image:
      url('//download-cos.yofish.com/xz-gongjushiyebu/20220915152107667-reward-bg.png');
    margin: auto;
    transition: all .3s;
    position: relative;
    .head {
      width: 550px;
      margin-top: 152px;
      margin-left: 131px;
    }
    img {
      width: 100%;
    }
    .reward-module {
      margin-left: 107px;
      margin-right: 48px;
      margin-top: 42px;
    }
    .reward-module-head {
      margin-bottom: 10px;
    }
    .reward-module-frame {
      overflow: hidden;
      &.module-frame-1 {
        width: 598px;
        height: 215px;
        .reward-cons {
          width: calc(100% - 12px);
        }
        .module-row {
          display: flex;
          justify-content: center;
        }
        .user-msg {
          margin-top: 40px;
        }
      }
      &.module-frame-2 {
        width: 598px;
        height: 770px;
        .reward-cons {
          width: calc(100% - 12px);
          display: flex;
          flex-flow: wrap;
          margin-top: 41px;
          overflow: hidden;
        }
        .module-row {
          width: 33.33%;
          margin: 0;
        }
      }
      &.module-frame-3 {
        width: 598px;
        height: 1792px;
        .reward-cons {
          width: calc(100% - 12px);
          display: flex;
          flex-flow: wrap;
          margin-top: 41px;
          overflow: hidden;
        }
        .module-row {
          width: 33.33%;
          margin: 0;
          .user-msg {
            margin-bottom: 39px;
            .phone-number {
              margin-top: 18px;
            }
          }
        }
      }
    }
    .hand {
      width: 158px;
      position: absolute;
      bottom: -10px;
      left: 0;
    }
    .module-row {
      margin: 0 50px 0 44px;
      text-align: center;
      img {
        width: 80px;
        border-radius: 100%;
      }
      .user-msg {
        // width: 130px;
        font-family: MiSans;
        font-weight: 400;
        color: #333333;
        font-size: 22px;
        margin-bottom: 42px;
        .phone-number {
          margin-top: 21px;
        }
      }
    }
  }
  .avatar img {
    border: .8PX solid #404eec;
  }
</style>
