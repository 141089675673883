<template>
  <div class="home-container">
    <div class="home-bg1 img-common">
      <div class="head img-common"></div>
      <div class="button img-common" @click="jumpUrl"></div>
    </div>
    <div class="home-bg2 img-common">
      <p class="desc">注：补打卡和当日多次打卡均算挑战失败</p>
    </div>
    <div class="rule-btn img-common" @click="isVisible = true"></div>
    <y-actionsheet :is-visible="isVisible"
      :isLockBgScroll="true"
      @close="closeActionsheet">
      <span slot="sTitle">活动规则</span>
      <div slot="custom">
        <rule />
      </div>
    </y-actionsheet>
  </div>
</template>
<script>
import YActionsheet from '@/components/actionsheet';
import rule from './rule';

export default {
  name: 'SaveupHome',
  components: { YActionsheet, rule },
  data() {
    return {
      isVisible: false,
    };
  },
  mounted() {
    window._monitor('pageset', 'hd_ym_imp', {
      eventName: '存钱任务活动页面曝光',
    });
  },
  methods: {
    closeActionsheet() {
      this.isVisible = false;
    },
    jumpUrl() {
      window._monitor('track', 'click_save_button', {
        eventName: '活动参加按钮点击',
      });
      window?.android?.goToActivity2('intent:#Intent;component=pkg/com.caiyi.accounting.jz.planmoney.PlanMoneyListActivity;end');
    },
  },
};
</script>
<style lang="scss" scoped>
  .home-container {
    width: 750px;
    margin: auto;
    position: relative;
    transition: all .3s;
    > .img-common {
      width: 100%;
      height: 920px;
    }
    .home-bg1 {
      overflow: hidden;
      background-image:
        url('//download-cos.yofish.com/xz-gongjushiyebu/2022091513585725-home-bg1.png');
    }
    .home-bg2 {
      background-image:
        url('//download-cos.yofish.com/yofish-gongjushiyebu/20220926165642797-home-bg2.png');
    }
    .head {
      width: 550px;
      height: 597px;
      margin-top: 152px;
      margin-left: 131px;
      background-image:
        url('//download-cos.yofish.com/xz-gongjushiyebu/20220921114616236-head.png');
    }
    .desc {
      // font-size: 10px;
      font-weight: 400;
      text-align: center;
      color: #fff;
      padding-left: 45px;
    }
    .button {
      width: 539px;
      height: 159px;
      margin: 20px auto 0;
      margin: 20px 130px 0 130px;
      background-image: url('../images/btn1.png');
      &:active {
        background-image: url('../images/btn2.png');
      }
    }
    .rule-btn {
      position: absolute;
      width: 88px;
      height: 297px;
      right: 0;
      top: 379px;
      background-image:
        url('//download-cos.yofish.com/xz-gongjushiyebu/20220915144259637-rule-btn.png');
    }
    /deep/ .nut-actionsheet-title {
      height: 80px;
      text-align: center;
      line-height: 80px;
      font-size: 34px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      text-align: center;
    }
    /deep/ .nut-actionsheet-panel {
      border-radius: 22px 22px 0 0;
      background-color: #fff;
    }
    /deep/ .nut-actionsheet-panel {
      min-height: 50%;
      .nut-actionsheet-custom {
        padding: 0 25px 25px 25px;
      }
    }
  }
</style>
