<template>
  <div class="page">
    <home v-if="isHome" />
    <reward v-if="!isHome" />
  </div>
</template>
<script>
import home from './components/home';
import reward from './components/reward';
import { getAppStatus } from '@/utils/app';

export default {
  name: 'ActivitySaveup',
  components: {
    home, reward,
  },
  mounted() {
    document.title = '攒钱计划活动页';
  },
  created() {
    this.blindMonitor();
  },
  computed: {
    isHome() {
      return false;
    },
    activityId() {
      return 1;
    },
    businessType() {
      return 2;
    },
    cuserId() {
      return getAppStatus().cuserId;
      // return '1e893452-da8a-44fa-aaeb-7f7cc6bd02b4';
    },
    appName() { // app 包名
      let appInfo = '{}';
      if (window.android) appInfo = window?.android?.jzAppInfo();
      if (window.ios) appInfo = window?.ios?.jzAppInfo();
      appInfo = JSON.parse(appInfo);
      let name = appInfo.name || '';
      name = encodeURIComponent(name || 'com.ttjz');
      return name;
    },
  },
  methods: {
    blindMonitor() {
      const { appName } = this;
      window._monitor('init', {
        businessType: this.businessType,
        businessId: this.activityId,
        cuserId: this.cuserId,
      }, {}, { name: appName });
    },
  },
};
</script>
<style lang="scss" scoped>
  .page {
    width: 100%;
    min-height: 100%;
    background: #001AC7;
  }
</style>
<style lang="scss">
  @import "@/assets/scss/index.scss";
</style>
